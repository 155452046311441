import React from "react";
import styles from "./Detail.module.css";

const Detail = ({ title, info }) => {
  return (
    <div className={styles.detail}>
      <p className={styles.title}>{title}</p>
      <p className={styles.info}>{info}</p>
    </div>
  );
};

export default Detail;
