import { ToastContainer } from "react-toastify";
import "./App.css";
import Login from "./pages/login/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Register from "./pages/register/Register";
import Profile from "./pages/profile/Profile";
import { UserProvider } from "./context/UserContext";
import Home from "./pages/home/home";
import ProtectedRoute from "../src/pages/profile/ProtectedRoute";
import FileUpload from "../src/pages/fileUpload/fileUpload";
import CreateFolder from "./pages/createFolder/createFolder";

function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <ToastContainer />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />}>
            <Route path="/upload" element={<FileUpload />} />
            <Route path="/create-folder" element={<CreateFolder />} />
          </Route>
          <Route path="/register" element={<Register />} />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
        </Routes>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;