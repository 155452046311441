import React from "react";
import styles from "./FileUpload.module.css";
import { PiUploadSimpleBold } from "react-icons/pi";
import { IoClose } from "react-icons/io5";

const FileUpload = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.fileUpload}>
        <div className={styles.headerContent}>
          <div className={styles.header}>
            <p className={styles.headingM}>Upload file</p>
            <p className={styles.headingXs}>
              Add your documents here, and you can upload up to 5 files max
            </p>
          </div>
          <div className={styles.close} onClick={onClose}>
            <IoClose />
          </div>
        </div>
        <div className={styles.uploadSection}>
          <div className={styles.uploadIcon}>
            <PiUploadSimpleBold color="dodgerBlue" fontSize="24px" />
          </div>
          <p className={styles.headingXs}>
            Drag your file(s) or <span>browse</span>
          </p>
        </div>
        <p className={styles.headingXs}>
          Only support .jpg, .png and .svg and zip files
        </p>
        <div className={styles.buttonContainer}></div>
      </div>
    </div>
  );
};

export default FileUpload;
