import React, { useEffect } from "react";
import styles from "./createFolder.module.css";
import { IoClose } from "react-icons/io5";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { folderSchema } from "../../schema/user.schema";
import { createFolderService } from "../../services/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CreateFolder = ({ isOpen, onClose, onFolderCreated }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(folderSchema),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const onSubmit = async (data) => {
    try {
      const response = await createFolderService(data);
      console.log(response);
      onFolderCreated(response);
      toast.success("Folder created!");
      navigate("/");
      onClose();
      reset();
    } catch (error) {
      console.log(error);
      toast.error(error.message || "Please try again.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.createFolder}>
        <div className={styles.headerContent}>
          <div className={styles.header}>
            <p className={styles.headingM}>Create folder</p>
          </div>
          <div className={styles.close} onClick={onClose}>
            <IoClose />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputContainer}>
            <Input
              placeholder="Folder name"
              name="name"
              type="text"
              register={register}
              error={errors.name}
            />
            <Input
              placeholder="Description"
              name="description"
              type="text"
              register={register}
              error={errors.description}
            />
            <Input
              placeholder="parentDirectoryHash"
              name="parentDirectoryHash"
              type="text"
              register={register}
              error={errors.parentDirectoryHash}
            />
          </div>
          <div className={styles.buttonContainer}>
            <Button text="Cancel" className="tertiary" onClick={onClose} />
            <Button text="Save" type="submit" className="primary" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateFolder;
