import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import styles from "./home.module.css";
import Button from "../../components/Button/Button";
import FileUpload from "../fileUpload/fileUpload";
import { Link, useNavigate } from "react-router-dom";
import CreateFolder from "../createFolder/createFolder";
import { FcFolder } from "react-icons/fc";

const Home = () => {
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [createFolderModalOpen, setCreateFolderModalOpen] = useState(false);
  const [folders, setFolders] = useState([]);

  // console.log(folders);

  const navigate = useNavigate();

  // const handleUploadButton = () => {
  //   setUploadModalOpen(true);
  //   navigate("/upload");
  // };

  const handleCloseModal = () => {
    setUploadModalOpen(false);
    navigate("/");
  };

  const handleFolderButton = () => {
    setCreateFolderModalOpen(true);
    navigate("/create-folder");
  };

  const handleCloseFolderModal = () => {
    setCreateFolderModalOpen(false);
    navigate("/");
  };

  const handleCreatedFolders = (newFolder) => {
    setFolders((prevFolders) => [...prevFolders, newFolder]);
  };

  return (
    <Layout>
      <div className={styles.header}>
        <p className={styles.headingM}> Cloud Storage</p>
        {/* <Button
          text="+ Upload file"
          className="secondary"
          onClick={handleUploadButton}
        /> */}
        <Button
          text="+ Create folder"
          className="secondary"
          onClick={handleFolderButton}
        />
      </div>
      <div className={styles.folderContainer}>
        <div className={styles.folder}>
          <FcFolder fontSize="48px" />
          <p className={styles.headingXs}>Folder name</p>
        </div>
        {/* {folders.map((folder, index) => (
          <div key={index} className={styles.folder}>
            {folder.name}
          </div>
        ))} */}
      </div>
      <FileUpload isOpen={uploadModalOpen} onClose={handleCloseModal} />
      <CreateFolder
        isOpen={createFolderModalOpen}
        onClose={handleCloseFolderModal}
        onFolderCreated={handleCreatedFolders}
      />
    </Layout>
  );
};

export default Home;
