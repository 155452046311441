import React from "react";
import styles from "./Header.module.css";
import { FaUser } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useUser } from "../../context/UserContext";

const Header = () => {
  const { user } = useUser();

  return (
    <div className={styles.header}>
      i am header
      <Link to="/profile">
        <div className={styles.profile}>
          {user ? (
            `${user.first_name} ${user.last_name}`
          ) : (
            <Link to="/login">Login to your account</Link>
          )}
          <div className={styles.profileImage}>
            <FaUser />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Header;
